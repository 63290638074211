import React, { useState } from 'react';
import { Row, Col, Container, Form } from 'react-bootstrap';
import * as Constants from "../../constants/constants"
import { Line } from 'react-chartjs-2';

export default function ReportsTable(props) {
    const reports = props.reports.sort((a, b) => (a.Workorder.WorkorderNumber > b.Workorder.WorkorderNumber) ? 1 : -1)
    const totals = props.totals[0] ? props.totals[0] : {}
    const methodTotals = props.methodTotals
    const splits = props.splits ? props.splits : []
    const [cols, setCols] = useState(["Trans. Date",/* "Customer", */ "W.O. #", "Parts Cost", "Sublet Cost", "Labor Price",
        "Parts Price", "Sublet Price"/* , "Disc. Amount" */,/* "Waste Cost", */ "Tax Coll.", "Credit Amount", "Amount Recvd", "W.O. Total", "W.O. Profit"])
    const summary = props.summary
    const data = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      };
      const options = {
        scales: {
          yAxes: [
            {
              type: 'linear',
              display: true,
             
            } 
          ],
        },
        tooltips: {
            enabled: true,
            callbacks: {
              // Customize the tooltip content
              label: function(tooltipItem, data) {
                const datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
                const value = tooltipItem.yLabel;
                return `${datasetLabel}: ${value.toLocaleString()} `;
              },
              title: function(tooltipItem, data) {
                return `Month: ${tooltipItem[0].label}`;
              },
            },
          },
      };
    function calculateAmountRecvd() {
        let sum = 0
        sum = props.methodTotals.reduce((acc, item) => acc + item.total, 0)
        return sum.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    function calculateWOTotal() {
        let sum = 0
        sum += totals.WOtotal ? totals.WOtotal : 0
        return sum.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    function removeItem(item_str) {
        let index = cols.indexOf(item_str)
        if (index !== -1) {
            console.log("successful remove.");
            let cpy = [...cols]
            cpy.splice(index, 1)
            console.log(cpy);
            setCols(cpy)
        }
        else {
            console.log("addItem.");
            let cpy = [...cols]
            if (item_str.length > 0) {
                cpy.push(item_str)
                console.log(cpy);
                setCols(cpy)
            }

        }

    }

    // eslint-disable-next-line
    function calculateMonthDifference(key1, index) {
        console.log("caluc month diff, ", index, summary.list, 'key1;', key1);
        if (index > 0 && index < summary.list[key1].length - 1) {
            let A = summary.list[key1][index - 1].total
            let B = summary.list[key1][index].total
            var percDiff = 100 * Math.abs((A - B) / ((A + B) / 2));
            if (A < B)
                return percDiff.toFixed(2)
            else
                return -percDiff.toFixed(2)
        }
        else
            return 0
    }

    return (

        <div style={{ paddingLeft: "5px", paddingRight: "5px", paddingBottom: "3rem", height: "80vh" }}>
            <Form style={{ fontSize: "small" }} onClick={(item) => removeItem(item.target.id)}>
                <Row style={{ marginLeft: "0px", marginBottom: "5px" }}>
                    {/* <div className="mb-3"> */}
                    <Form.Check style={{ marginRight: "10px" }} id="Trans. Date" defaultChecked={cols.includes("Trans. Date")} label="Trans. Date" type="checkbox" />
                    <Form.Check style={{ marginRight: "10px" }} id="W.O. #" defaultChecked={cols.includes("W.O. #")} label="W.O. #" type="checkbox" />
                    <Form.Check style={{ marginRight: "10px" }} id="Customer" defaultChecked={cols.includes("Customer")} label="Customer" type="checkbox" />
                    <Form.Check style={{ marginRight: "10px" }} id="Method" defaultChecked={cols.includes("Method")} label="Method" type="checkbox" />
                    <Form.Check style={{ marginRight: "10px" }} id="Labor Cost" defaultChecked={cols.includes("Labor Cost")} label="Labor Cost" type="checkbox" />
                    <Form.Check style={{ marginRight: "10px" }} id="Waste Cost" defaultChecked={cols.includes("Waste Cost")} label="Waste Cost" type="checkbox" />
                    <Form.Check style={{ marginRight: "10px" }} id="Parts Cost" defaultChecked={cols.includes("Parts Cost")} label="Parts Cost" type="checkbox" />
                    <Form.Check style={{ marginRight: "10px" }} id="Sublet Cost" defaultChecked={cols.includes("Sublet Cost")} label="Sublet Cost" type="checkbox" />
                    <Form.Check style={{ marginRight: "10px" }} id="Labor Price" defaultChecked={cols.includes("Labor Price")} label="Labor Price" type="checkbox" />
                    <Form.Check style={{ marginRight: "10px" }} id="Parts Price" defaultChecked={cols.includes("Parts Price")} label="Parts Price" type="checkbox" />
                    <Form.Check style={{ marginRight: "10px" }} id="Sublet Price" defaultChecked={cols.includes("Sublet Price")} label="Sublet Price" type="checkbox" />
                    <Form.Check style={{ marginRight: "10px" }} id="Disc. Amount" defaultChecked={cols.includes("Disc. Amount")} label="Disc. Amount" type="checkbox" />
                    <Form.Check style={{ marginRight: "10px" }} id="Tax Coll." defaultChecked={cols.includes("Tax Coll.")} label="Tax Coll." type="checkbox" />
                    <Form.Check style={{ marginRight: "10px" }} id="Credit Amount" defaultChecked={cols.includes("Credit Amount")} label="Credit Amount" type="checkbox" />
                    <Form.Check style={{ marginRight: "10px" }} id="Amount Recvd" defaultChecked={cols.includes("Amount Recvd")} label="Amount Recvd" type="checkbox" />
                    <Form.Check style={{ marginRight: "10px" }} id="W.O. Total" defaultChecked={cols.includes("W.O. Total")} label="W.O. Total" type="checkbox" />
                    <Form.Check style={{ marginRight: "10px" }} id="W.O. Profit" defaultChecked={cols.includes("W.O. Profit")} label="W.O. Profit" type="checkbox" />
                    {/* </div> */}
                </Row>
            </Form>

            <Container style={{ minWidth: "100%", wordWrap: "normal", fontSize: "13px", height: "100%", overflowY: "scroll", borderStyle: "outset", position: "relative", /* minWidth: "1070px" */ }} >

                {reports.length > 0  ?
                    <div >
                        <Row style={{ textAlign: "right" }} >
                            {cols.includes("Trans. Date") ? <Col >Trans. Date</Col> : null}
                            {cols.includes("W.O. #") ? <Col >W.O. #</Col> : null}
                            {cols.includes("Customer") ? <Col >Customer</Col> : null}
                            {cols.includes("Method") ? <Col >Method</Col> : null}
                            {cols.includes("Labor Cost") ? <Col >Labor Cost</Col> : null}
                            {cols.includes("Waste Cost") ? <Col >Waste Cost</Col> : null}
                            {cols.includes("Parts Cost") ? <Col >Parts Cost</Col> : null}
                            {cols.includes("Sublet Cost") ? <Col >Sublet Cost</Col> : null}
                            {cols.includes("Labor Price") ? <Col >Labor Price</Col> : null}
                            {cols.includes("Parts Price") ? <Col >Parts Price</Col> : null}
                            {cols.includes("Sublet Price") ? <Col >Sublet Price</Col> : null}
                            {cols.includes("Disc. Amount") ? <Col>Disc. Amount</Col> : null}
                            {cols.includes("Tax Coll.") ? <Col >Tax Coll.</Col> : null}
                            {cols.includes("Credit Amount") ? <Col >Credit Amount</Col> : null}
                            {cols.includes("Amount Recvd") ? <Col >Amount Recvd </Col> : null}
                            {cols.includes("W.O. Total") ? <Col >W.O. Total </Col> : null}
                            {cols.includes("W.O. Profit") ? <Col >W.O. Profit </Col> : null}
                        </Row>
                        <hr />

                        {reports.length < 100 ?
                        reports.map((item, key) =>

                            item.Totals.PaymentMethod === "Split" ?
                                <Row className="jobcard" style={{ textAlign: "right" }} key={key}
                                    onClick={props.openInvoice.bind(this, item)}>
                                    {cols.includes("Trans. Date") ? <Col>{item.Totals.TransDate ? Constants.dateFormatter(item.Totals.TransDate) : '--'}</Col> : null}
                                    {cols.includes("W.O. #") ? <Col>{item.Workorder.WorkorderNumber}</Col> : null}
                                    {cols.includes("Customer") ? <Col style={{ textAlign: "left", whiteSpace: "pre" }} >{`${item.Customer.Firstname ? item.Customer.Firstname : ''} ${item.Customer.Lastname}`}</Col> : null}
                                    {cols.includes("Method") ? <Col>{item.Totals.TransType ? `${item.Totals.TransType}*` : '--'}</Col> : null}
                                    {cols.includes("Labor Cost") ? <Col>--</Col> : null}
                                    {cols.includes("Waste Cost") ? <Col>--</Col> : null}
                                    {cols.includes("Parts Cost") ? <Col>--</Col> : null}
                                    {cols.includes("Sublet Cost") ? <Col>--</Col> : null}
                                    {cols.includes("Labor Price") ? <Col>--</Col> : null}
                                    {cols.includes("Parts Price") ? <Col>--</Col> : null}
                                    {cols.includes("Sublet Price") ? <Col>--</Col> : null}
                                    {cols.includes("Disc. Amount") ? <Col>--</Col> : null}
                                    {cols.includes("Tax Coll.") ? <Col>--</Col> : null}
                                    {cols.includes("Credit Amount") ? <Col>--</Col> : null}
                                    {cols.includes("Amount Recvd") ? <Col> {item.Totals.AmountRecvd ? item.Totals.AmountRecvd.toFixed(2) : '--'} </Col> : null}
                                    {cols.includes("W.O. Total") ? <Col>--</Col> : null}
                                    {cols.includes("W.O. Profit") ? <Col>--</Col> : null}
                                </Row>

                                :

                                <Row className="jobcard" style={{ textAlign: "right" }} key={key}
                                    onClick={props.openInvoice.bind(this, item)}>
                                    {cols.includes("Trans. Date") ? <Col>{Constants.dateFormatter(item.Totals.DateClosed)}</Col> : null}
                                    {cols.includes("W.O. #") ? <Col>{item.Workorder.WorkorderNumber}</Col> : null}
                                    {cols.includes("Customer") ? <Col style={{ textAlign: "left", whiteSpace: "pre" }} >{`${item.Customer.Firstname ? item.Customer.Firstname + ' ' : ''}${item.Customer.Lastname}`}</Col> : null}
                                    {cols.includes("Method") ? <Col >{item.Payments[0].Type ? item.Payments[0].Type : ''}</Col> : null}
                                    {cols.includes("Labor Cost") ? <Col>--{/* {item.Totals.LaborSubtotal ? item.Totals.LaborSubtotal.toFixed(2) : 0.00.toFixed(2)} */} {/* LABOR COST */}</Col> : null}
                                    {cols.includes("Waste Cost") ? <Col>{item.Totals.WasteCost ? item.Totals.WasteCost.toFixed(2) : 0.00.toFixed(2)}</Col> : null}
                                    {cols.includes("Parts Cost") ? <Col>{item.Totals.PartsCost ? item.Totals.PartsCost.toFixed(2) : 0.00.toFixed(2)}</Col> : null}
                                    {cols.includes("Sublet Cost") ? <Col>{item.Totals.SubletCost ? item.Totals.SubletCost.toFixed(2) : 0.00.toFixed(2)}</Col> : null}
                                    {cols.includes("Labor Price") ? <Col>{item.Totals.LaborSubtotal ? item.Totals.LaborSubtotal.toFixed(2) : 0.00.toFixed(2)}</Col> : null}
                                    {cols.includes("Parts Price") ? <Col>{item.Totals.PartsSubtotal ? item.Totals.PartsSubtotal.toFixed(2) : 0.00.toFixed(2)} {/* PARTS PRICE */}</Col> : null}
                                    {cols.includes("Sublet Price") ? <Col>{item.Totals.SubletSubtotal ? item.Totals.SubletSubtotal.toFixed(2) : 0.00.toFixed(2)}</Col> : null}
                                    {cols.includes("Disc. Amount") ? <Col>-- {/* DISCOUNT */}</Col> : null}
                                    {cols.includes("Tax Coll.") ? <Col>{item.Totals.WorkorderTax ? item.Totals.WorkorderTax.toFixed(2) : 0.00.toFixed(2)}</Col> : null}
                                    {cols.includes("Credit Amount") ? <Col>{item.Totals.CreditSubtotal ? item.Totals.CreditSubtotal.toFixed(2) : 0.00.toFixed(2)}</Col> : null}
                                    {cols.includes("Amount Recvd") ? <Col>
                                        {
                                            splits.filter((split) => {
                                                return (split.Workorder.WorkorderNumber === item.Workorder.WorkorderNumber)
                                            }).length > 0 ? splits.filter((split) => {
                                                return (split.Workorder.WorkorderNumber === item.Workorder.WorkorderNumber)
                                            })[0].amount.toFixed(2) : item.Totals.WorkorderTotal ? item.Totals.WorkorderTotal.toFixed(2) : 0.00.toFixed(2)

                                        }

                                    </Col> : null}
                                    {cols.includes("W.O. Total") ? <Col>{item.Totals.WorkorderTotal ? item.Totals.WorkorderTotal.toFixed(2) : 0.00.toFixed(2)}</Col> : null}
                                    {cols.includes("W.O. Profit") ? <Col>--</Col> : null}
                                </Row>
                        )
                        : null}

                        {/* TOTALS */}
                        <hr />
                        <Row style={{ textAlign: "right" }}>
                            {cols.includes("Trans. Date") ? <Col >{/* Trans. Date */}</Col> : null}
                            {cols.includes("W.O. #") ? <Col >{/* W.O. # */}</Col> : null}
                            {cols.includes("Customer") ? <Col > {/* NAME */}</Col> : null}
                            {cols.includes("Method") ? <Col > {/* PAYMENT METHOD */}</Col> : null}

                            {cols.includes("Labor Cost") ? <Col >{/* Labor Cost */}--{/* {totals.LaborSubtotal ? totals.LaborSubtotal.toFixed(2) : 0.00.toFixed(2)} */}</Col> : null}
                            {cols.includes("Waste Cost") ? <Col >{/* Parts Cost */}{totals.WasteCost ? totals.WasteCost.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0.00.toFixed(2)}</Col> : null}
                            {cols.includes("Parts Cost") ? <Col >{/* Parts Cost */}{totals.PartsCost ? totals.PartsCost.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0.00.toFixed(2)}</Col> : null}
                            {cols.includes("Sublet Cost") ? <Col >{/* Sublet Cost */}{totals.SubletCost ? totals.SubletCost.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0.00.toFixed(2)}</Col> : null}
                            {cols.includes("Labor Price") ? <Col >{/* Labor Price */}{totals.LaborPrice ? totals.LaborPrice.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0.00.toFixed(2)}</Col> : null}
                            {cols.includes("Parts Price") ? <Col >{/* Parts Price */}{totals.PartsPrice ? totals.PartsPrice.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0.00.toFixed(2)}</Col> : null}
                            {cols.includes("Sublet Price") ? <Col >{/* Sublet Price */}{totals.SubletPrice ? totals.SubletPrice.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0.00.toFixed(2)}</Col> : null}
                            {cols.includes("Disc. Amount") ? <Col>{/* Disc. Amount */}--</Col> : null}
                            {cols.includes("Tax Coll.") ? <Col >{/* Tax Coll. */}{totals.WOtax ? totals.WOtax.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0.00.toFixed(2)}</Col> : null}
                            {cols.includes("Credit Amount") ? <Col >{/* Credit Amount */}{totals.Credit ? totals.Credit.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0.00.toFixed(2)} </Col> : null}
                            {cols.includes("Amount Recvd") ? <Col >{/* Amount Recvd */} {calculateAmountRecvd()} </Col> : null}
                            {cols.includes("W.O. Total") ? <Col >{/* W.O. Total */}{calculateWOTotal()} </Col> : null}
                            {cols.includes("W.O. Profit") ? <Col >{/* W.O. Profit */}--</Col> : null}
                        </Row>

                        <hr />


                        ----- Payment Method Breakdown -----
                            {methodTotals.map((item, key) =>
                            <Row key={key}>
                                <Col sm={1}>{item.type}:</Col>
                                <Col sm={1} style={{ textAlign: "right" }}>{item.total ? item.total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0.00}</Col>
                            </Row>
                        )}

                        {/* Summary Report */}
                        <hr />

                    </div>
                    : null}


                {summary.length > 0 ?
                summary.map((item,key)=>{
                    return(
                        <Row key={key}>
                            <Col style={{ marginLeft: "30px", borderWidth: "3px", borderRight: "ridge", marginRight: "10px" }} key={key}>
                                <Row style={{ fontWeight: "700" }} > {item.year}   </Row>
                                <Row> {item.sum ? item.sum.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0.00}</Row>
                                <Row> Total:  </Row>
                                <Row> Car Count: </Row>
                                
                            </Col>
                            {item.months.length > 0 ? 
                                item.months.map((m,k)=>
                                    {
                                        return (
                                            <Col key={k}>
                                                <Row style={{ fontWeight: "700" }} > {m.month} </Row>
                                                <Row> {m.total ? m.total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0.00}  </Row>
                                                <Row> {m.count}  </Row>
                                            </Col>
                                        )
                                    })
                            : null}
                          

                            {
                                /* Filler months */
                                item.months.length-12 !== 0 ? 
                                    [...Array(12 - item.months.length).keys()].map(i => {
                                        return (
                                            <Col key={i}>
                                                <Row style={{ fontWeight: "700" }} >  </Row>
                                                <Row> </Row>

                                            </Col>
                                        )})
                                 : null
                            }
                            
                        </Row>
                    )
                })
                
                : null}

                {summary.length > 0 ?
                <div style={{display:"flex"}}>
                    <div style={{height:"40rem", width:"40rem", marginLeft:"10px", zoom:"111%" }}>
                                    <Line data={{...data, datasets: props.revenueChartDataset}} options={options} />

                    </div> 
                    <div style={{height:"40rem", width:"40rem", marginLeft:"10px", zoom:"111%" }}>
                                    <Line data={{...data,datasets: props.carChartDataset}} options={options} />

                    </div> 
                    </div>
                : null}


        
                <br />
            </Container>
        </div>


    );

}