import React, { useState } from 'react';
import axios from 'axios';
import * as Constants from './../../constants/constants'
import { Form, Button, Card, Modal, Alert } from 'react-bootstrap'
import ReportsTable from "./ReportsTable.js"
import CheckoutModal from "./Modals/CheckoutModal.js"
import Loading from './../../global_components/Loading.js'
import SearchScreenContents from "../search_screen/SearchScreen.js"
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";

export default function ReportScreen(props) {
    let today = new Date()
    let date = (today.getMonth() + 1) + '/' + today.getDate() + '/' + today.getFullYear();
    const [reports, setReports] = useState({ list: [], length: 0 })
    const [stats, setStats] = useState({ totalAmount: 0 })
    const [searchDate, setSearchDate] = useState({ start: date, end: date })
    const [totals, setTotals] = useState({ totals: {}, splitTotals: {}, splits: [] })
    const [methodTotals, setMethodTotals] = useState({ totals: [] })
    const [loading, setLoading] = useState(false)
    const [invoiceInfo, setInvoiceInfo] = useState({})
    const [summaryReport, setSummaryReport] = useState({})
    const [revenueChartDataset, setRevenueChartDataset] = useState([])
    const [carCountChartDataset, setCarCountChartDataset] = useState([])
    const [error, setError] = useState("")
    const [checkoutAmounts, setCheckoutAmounts] = useState({ cash: '', card: '', check: '', other: '', slips: [], boxStart: 200, boxEnd: 200 })
    const [modals, setModals] = useState({
        checkout: false,
        invoice: false,
    })

    async function generateReport() {
        setLoading(true)
        try {
            const res_data = await axios.get(`${Constants.port}/invoices/dailySalesReport`);
            console.log('fetching report dataa: ', res_data);
            setStats({ ...stats, totalAmount: res_data.data.response.length })
            setReports({ list: res_data.data.response, length: res_data.data.amount })
            setTotals({ totals: res_data.data.totals, splitTotals: res_data.data.splitPayments, splits: res_data.data.splits })
            setMethodTotals({ totals: res_data.data.methodTotals })
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log("error getReports() ReportScreen.js:18")
            if (error.response)
                props.setError({ show: true, message: error.response.data.error })
            else if (error.request)
                props.setError({ show: true, message: error.request })
            else
                props.setError({ show: true, message: props.error })
        }
    }

    async function setDate(e, option) {
        //console.log("e:", e, "e._d", e._d)
        var date;
        if(e._d){
            let today = new Date(e._d)
            date = (today.getMonth() + 1) + '/' + today.getDate() + '/' + today.getFullYear();
        }
        else
            date = e
        //console.log("new date, date", date);
        if (option === "start")
            setSearchDate({ ...searchDate, start: date })
        else
            setSearchDate({ ...searchDate, end: date })

    }

    async function openInvoice(invoice) {
        console.log("Opening invoice, reports screen:", invoice);
        /* setLoading(true) */
        setInvoiceInfo(invoice)
        setModals({ ...modals, invoice: true })
    }

    function getChartData(summary){
        if(summary.length <= 0)
            return;
        let revenueChart = []
        let carChart = []
        summary.forEach((item,key)=>{
            let year = item.year 
            let months_totalRevenue = []  
          
            item.months.map((month,key)=>{
                return months_totalRevenue.push(month.total) 
            })
            revenueChart.push({
                label: year,
                data: months_totalRevenue,
                fill: false,
                borderColor: "#" + ((1<<24)*Math.random() | 0).toString(16),
            })
            
            let months_totalCar = []  
            item.months.map((month,key)=>{
                return months_totalCar.push(month.count) 
            })
            carChart.push({
                label: year,
                data: months_totalCar,
                fill: false,
                borderColor: "#" + ((1<<24)*Math.random() | 0).toString(16),
            })
        })
        setCarCountChartDataset(carChart)
        setRevenueChartDataset(revenueChart)
    }
    
    async function generateRangeReport() {
        console.log("generate range report, searchDate", searchDate);
        setLoading(true)

        try {
            const res_data = await axios.post(`${Constants.port}/invoices/salesReportRange`, searchDate);
            console.log('fetching report_range data: ', res_data);
            setStats({ ...stats, totalAmount: res_data.data.response.length })
            setReports({ list: res_data.data.response, length: res_data.data.amount })
            setTotals({ totals: res_data.data.totals, splitTotals: res_data.data.splitPayments, splits: res_data.data.splits })
            setMethodTotals({ totals: res_data.data.methodTotals })
            setSummaryReport(res_data.data.summary)
            getChartData(res_data.data.summary)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log("error generateRangeReport() ReportScreen.js:, ZXYd61A")
            if (error.response)
                props.setError({ show: true, message: error.response.data.error })
            else if (error.request)
                props.setError({ show: true, message: error.request })
            else
                props.setError({ show: true, message: props.error })
        }
    }

    async function submitCheckout(vals) {

        let sum = 0
        sum = methodTotals.totals.reduce((acc, item) => acc + item.total, 0)
        //sum = sum.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")

        console.log("submitting checokut...", vals, vals.slips, sum);
        const cpy = {...vals}
        setLoading(true)
        try {
            const res_data = await axios.post(`${Constants.port}/checkouts/submit`, Object.assign(cpy, { sum: sum, slips: cpy.slips }));
            console.log('fetching data, submit checkout: ', res_data);
            setLoading(false)
        } catch (error) {
            setLoading(false)
            if(error.response)
                setError(error.response.data.error)
            else    
                setError("error submitting checkout, reportScreen")
            console.log("error submitCheckout() ReportScreen", error)
        }
        setModals({ ...modals, checkout: false })
    }



    return (
        <div className="cardpadding">
            
            <Modal show={error!==""} animation={false} >
            <div className="spinnerpadding">
                <div className="overlay">
                <Alert dismissible variant="danger" onClose={()=>setError("")}>
               <Alert.Heading> {error} </Alert.Heading>
            </Alert>
                </div>
            </div>
        </Modal>
            <Modal
                show={modals.invoice}
                onHide={() => setModals({ ...modals, invoice: false })}
                centered
                dialogClassName="search_modal_content"
            >
                <SearchScreenContents from="report_screen" info={invoiceInfo} />
            </Modal>
            <Card>
                <Card.Header style={{ fontSize: "18px", paddingRight: "10px" }}>
                    <Form inline>

                        <Datetime onChange={(e) => setDate(e, "start")} closeOnSelect dateFormat="MM/DD/YYYY" timeFormat={false} />
                        {` --> `}
                        <Datetime onChange={(e) => setDate(e, "end")} dateFormat="MM/DD/YYYY" closeOnSelect timeFormat={false} />

                        <Button style={{ marginLeft: "10px", marginRight: "10px" }} variant="secondary" onClick={() => generateRangeReport()}>Submit</Button>
                        <Button style={{ marginLeft: "10px", marginRight: "10px" }} variant="info" onClick={() => generateReport()}>Today</Button>
                        {
                            reports.length > 0 ?
                                <Button style={{marginRight: "10px" }} variant="info" onClick={() => setModals({ ...modals, checkout: true })}>Checkout</Button>
                                : null
                        }

                        {!loading ? null : <Loading />}

                        <p style={{ position: "absolute", top: "0px", right: "10px" }}> Total Amount: {stats.totalAmount}</p>
                    </Form>

                </Card.Header>

                <ReportsTable
                    revenueChartDataset={revenueChartDataset}
                    carChartDataset={carCountChartDataset}
                    openInvoice={(invoice) => openInvoice(invoice)}
                    splits={totals.splits}
                    reports={reports.list}
                    amount={reports.length}
                    totals={totals.totals}
                    methodTotals={methodTotals.totals}
                    summary={summaryReport}
                />

                {modals.checkout ?
                    <CheckoutModal 
                        from='report'
                        show={modals.checkout}
                        checkoutAmounts={checkoutAmounts}
                        updateCheckoutAmounts={(newAmounts)=>setCheckoutAmounts(newAmounts)}
                        close={() => setModals({ ...modals, checkout: false })}
                        total={methodTotals.totals.reduce((total, obj) => obj.total + total,0)}
                        submit={(vals) => submitCheckout(vals)}
                    />
                    : null}

            </Card>

        </div>
    );
}